<template>
    <div
      v-if="
        getUser.role.slug === 'responsable-relation-client' ||
          getUser.role.slug === 'super-administrateur' ||
          getUser.role.slug === 'charge-de-clientele'
      "
    >
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>Filtrages</h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="10"
              lg="10"
              class="mb-md-0 mb-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="4"
                >
                  <label for="client">Client</label>
                  <v-select
                    v-model="payloadFilter.customer_id"
                    :options="customerOptions"
                    class="invoice-filter-select"
                    label="name"
                    :reduce="(customer) => customer.id"
                  >
                    <template v-slot:no-options>
                      <b-spinner
                        v-if="isCustomersWithoutPaginationLoading"
                        style="width: 2.5rem; height: 2.5rem"
                        class="align-middle text-info"
                      />
                      <span
                        v-else
                        class="mt-1 font-medium-1"
                      >Aucun client ne correspond</span>
                    </template>
                  </v-select>
                </b-col>
  
                <b-col
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                >
                  <label for="service">Services</label>
                  <v-select
                    v-model="payloadFilter.service_id"
                    :options="serviceOptions"
                    class="invoice-filter-select"
                    label="name"
                    :reduce="(service) => service.id"
                  >
                    <template v-slot:no-options>
                      <b-spinner
                        v-if="isServicesWithoutPaginationLoading"
                        style="width: 2.5rem; height: 2.5rem"
                        class="align-middle text-info"
                      />
                      <span
                        v-else
                        class="mt-1 font-medium-1"
                      >Aucun service ne correspond</span>
                    </template>
                  </v-select>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                >
                  <label for="status">Statut</label>
                  <v-select
                    v-model="payloadFilter.order_status"
                    :options="statusOptions"
                    class="invoice-filter-select"
                    :reduce="(item) => item.value"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">
                        {{ label }}
                      </span>
                    </template>
                  </v-select>
                </b-col>
  
                <b-col
                  cols="12"
                  sm="6"
                  md="2"
                  lg="2"
                  class="mt-2"
                >
                  <b-button
                    variant="primary"
                    @click="applyFilterOrderAction()"
                  >
                    <div v-if="isReLoadFilterDataOrders">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>
                    <span
                      v-else
                      class="text-nowrap font-medium-1"
                    >Filtrer</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card-code>
        <div class="">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-end mb-2"
            >
              <b-button
                variant="primary"
                @click="reLoadDataPunctualOrdersAction()"
              >
                <div v-if="isReLoadDataPunctualOrders">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span
                  v-else
                  class="text-nowrap font-medium-1"
                >Actualiser</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
  
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :is-loading="isOrdersLoading"
        >
          <template slot="emptystate">
            <div class="text-center">
              <span> {{ isOrdersLoading ? "" : "Liste vide" }} </span>
            </div>
          </template>
          <template slot="loadingContent">
            <div class="text-center text-info">
              <b-spinner class="align-middle" />
            </div>
          </template>
  
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <div
              v-if="props.column.field === 'user'"
              class="text-nowrap text-center align-middle"
            >
              <span class="d-block text-nowrap text-info font-small-5">{{
                props.row.user.full_name
              }}</span>
  
              <span class="text-success font-small-4">{{
                props.row.user.phone_number
              }}</span>
            </div>
  
            <!-- Column: Service -->
            <div
              v-else-if="props.column.field === 'service'"
              class="align-middle text-center"
            >
              <span class="font-small-5 text-info">
                {{ props.row.service.name }}
              </span>
            </div>
  
            <!-- Column: Avance payée -->
            <div
              v-else-if="props.column.field === 'advance'"
              class="align-middle text-center"
            >
              <span class="font-small-5 text-info">
                {{  props.row.accepted_offer_id !== null ? (props.row.remaining_order_price - props.row.accepted_offer.price) : 0 }} FCFA
              </span>
            </div>
  
            <!-- AVANCE DE L'OFFRE A REMETTRE  (props.row.remaining_order_price - props.row.accepted_offer.price) -->
  
            <!-- Column: Avance payée -->
            <div
              v-else-if="props.column.field === 'remaining'"
              class="align-middle text-center"
            >
              <span class="font-small-5 text-info">
                {{ props.row.remaining_order_price !== null ? props.row.remaining_order_price : 0  }} FCFA
              </span>
            </div>
  
            <!-- Column: Status -->
            <div
              v-else-if="props.column.field === 'setStatus'"
              class="align-middle text-center font-small-5"
            >
              <b-badge
                pill
                :variant="`light-${resolveOrderStatusVariant(props.row.status, props.row.type_of_payment)}`"
                class="text-capitalize text-nowrap font-medium-1"
              >
                {{ props.row.setStatus }}
              </b-badge>
            </div>
  
            <!-- Column: Action -->
            <div
              v-else-if="props.column.field === 'action'"
              class="align-middle text-center"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                      class="text-info align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :id="`details${props.row.id}`"
                    class="itemActions cursor-pointer"
                    @click="putPunctualOrdersInStoreAction(props.row)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="text-info mr-1"
                      size="25"
                    />
                    <span>Détails</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.status==0"
                    :id="`details${props.row.id}`"
                    class="itemActions cursor-pointer"
                    @click="openWhatsapp(props.row)"
                  >
                    <feather-icon
                      icon="MessageSquareIcon"
                      class="text-info mr-1"
                      size="25"
                    />
                    <span>Contactez le client</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </div>
  
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
  
          <!-- pagination -->
        </vue-good-table>
        <div class="d-flex justify-content-between mt-2 flex-wrap">
          <b-button
            :disabled="metaData.prev_page_url == null"
            variant="outline-secondary"
            @click="applyLoadMoreOrderAction(metaData.prev_page_url)"
          >
            <span class="text-white text-nowrap font-medium-2">Page précédente</span>
          </b-button>
  
          <div>
            <span class="text-white font-medium-2">{{ metaData.current_page }} sur
              {{ metaData.last_page }} pages</span>
          </div>
  
          <div>
            <span class="text-white font-medium-2">Total :{{ total }}</span>
          </div>
  
          <b-button
            :disabled="metaData.next_page_url == null"
            variant="outline-secondary"
            @click="applyLoadMoreOrderAction(metaData.next_page_url)"
          >
            <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
          </b-button>
        </div>
      </b-card-code>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BAvatar,
    BRow,
    BCol,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BSpinner,
    BButton,
  } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import { mapActions, mapGetters } from 'vuex'
  import vSelect from 'vue-select'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import store from '@/store/index'
  import { codeColumnSearch } from '@/views/order/punctual/code'
  
  export default {
    components: {
      BCardCode,
      BCard,
      BRow,
      vSelect,
      BCol,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BCardHeader,
      BCardBody,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BTooltip,
      BSpinner,
      BButton,
    },
    data() {
      return {
        isReLoadFilterDataOrders: false,
        customerOptions: [],
        serviceOptions: [],
        statusOptions: [
          {
            label: 'Offre proposée',
            value: 1,
          },
          {
            label: 'En attente',
            value: 0,
          },
          {
            label: 'Payée',
            value: 2,
          },
          {
            label: 'Terminée',
            value: 3,
          },
        ],
        payloadFilter: {
          customer_id: '',
          service_id: '',
          order_status: '',
        },
        isReLoadDataPunctualOrders: false,
        dir: false,
        codeColumnSearch,
        columns: [
          {
            label: 'Client',
            field: 'user',
            filterOptions: {
              enabled: false,
              placeholder: 'Rechercher par Client',
              filterFn(data, filterString) {
                return data.full_name
                  .toLowerCase()
                  .includes(filterString.toLowerCase())
              },
            },
          },
          {
            label: 'Service demandé',
            field: 'service',
          },
          {
            label: 'Status',
            field: 'setStatus',
          },
          {
            label: 'Action',
            field: 'action',
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    computed: {
      ...mapGetters('orders', ['getOrders']),
      ...mapGetters(['isOrdersLoading', 'isCustomersWithoutPaginationLoading', 'isServicesWithoutPaginationLoading']),
      ...mapGetters('auth', ['getUser']),
      ...mapGetters('customers', ['getCustomersWithoutPagination']),
      ...mapGetters('services', ['getServicesWithoutPagination']),
  
      metaData() {
        const meta = {
          prev_page_url: '',
          next_page_url: '',
          current_page: '',
          last_page: '',
          current_page_url: '',
        }
        if (this.getOrders != null) {
          meta.prev_page_url = this.getOrders.prev_page_url
          meta.next_page_url = this.getOrders.next_page_url
          meta.current_page = this.getOrders.current_page
          meta.last_page = this.getOrders.last_page
          meta.current_page_url = `${this.getOrders.path}?page=${this.getOrders.current_page}`
          this.total = this.getOrders.total;
  
        }
        return meta
      },
  
      direction() {
        if (store.state.appConfig.isRTL) {
          this.dir = true
          return this.dir
        }
        this.dir = false
        return this.dir
      },
    },
  
    watch: {
      payloadFilter: {
        handler(newValue, oldValue) {
          const keys = Object.keys(newValue)
          keys.forEach(key => {
            if (newValue[key] == null) {
              newValue[key] = ''
              console.log(this.payloadFilter)
            }
          })
        },
        deep: true,
      },
      isOrdersLoading(val) {
        if (val === false) {
          this.rows = this.customTab(this.getOrders.data)
        }
      },
      isCustomersWithoutPaginationLoading(val) {
        if (val === false) {
          this.getCustomersWithoutPagination.forEach(element => {
            this.customerOptions.push({
              id: element.id,
              name: element.customer_full_name,
            })
          })
        }
      },
      isServicesWithoutPaginationLoading(val) {
        if (val === false) {
          this.getServicesWithoutPagination.forEach(element => {
            this.serviceOptions.push({
              id: element.id,
              name: element.name,
            })
          })
        }
      },
    },
    created() {
      this.getOrders.data != null
        ? (this.rows = this.customTab(this.getOrders.data))
        : null
  
      if (this.isOrdersLoading === false) {
        this.rows = this.customTab(this.getOrders.data)
      }
    },
    mounted() {
      if (this.getCustomersWithoutPagination.length != 0) {
        this.getCustomersWithoutPagination.forEach(element => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          })
        })
      }
  
      if (this.getServicesWithoutPagination.length != 0) {
        this.getServicesWithoutPagination.forEach(element => {
          this.serviceOptions.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
    methods: {
      ...mapActions('orders', ['loadMoreOrderAction', 'getOrdersAction', 'filterPonctualOrderAction']),
  
      applyFilterOrderAction() {
        const allIsNUl = Object.values(this.payloadFilter).every(el => el === '')
  
        if (allIsNUl) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Warning',
                icon: 'CheckIcon',
                text: 'Veuillez sélectionner au moins un champ du filtre.',
                variant: 'warning',
              },
            },
            {
              position: 'top-center',
            },
          )
        } else {
          this.isReLoadFilterDataOrders = true
          this.$store.commit('SET_IS_PONCTUAL_ORDERS_FILTER_LOADING', true)
          this.rows = []
  
          this.filterPonctualOrderAction(this.payloadFilter)
            .then(response => {
              console.log(response.data)
              this.isReLoadFilterDataOrders = false
              this.$store.commit('SET_IS_PONCTUAL_ORDERS_FILTER_LOADING', false)
              this.rows = this.customTab(response.data)
              console.log('getter', this.getOrders)
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
  
      putPunctualOrdersInStoreAction(order) {
        this.$store.commit('orders/SET_ORDER', order)
        this.$router.push({
          name: 'admin-orders-business-punctual-details',
          params: { id: order.id },
        })
      },
  
      reLoadDataPunctualOrdersAction() {
        this.payloadFilter.customer_id = ''
        this.payloadFilter.order_status = ''
        this.payloadFilter.service_id = ''
  
        this.isReLoadDataPunctualOrders = true
        this.$store.commit('SET_IS_ORDERS_LOADING', true)
        this.rows = []
  
        this.getOrdersAction()
          .then(response => {
            this.isReLoadDataPunctualOrders = false
            this.$store.commit('SET_IS_ORDERS_LOADING', false)
          })
          .catch(error => {
            this.isReLoadDataPunctualOrders = false
            this.$store.commit('SET_IS_ORDERS_LOADING', false)
          })
      },
  
      applyLoadMoreOrderAction(url) {
        this.$store.commit('SET_IS_ORDERS_LOADING', true)
        this.rows = []
        this.loadMoreOrderAction(url)
          .then(() => {
            this.$store.commit('SET_IS_ORDERS_LOADING', false)
          })
          .catch(() => {
            this.$store.commit('SET_IS_ORDERS_LOADING', false)
          })
      },
  
      resolveOrderStatusVariant: (status, type) => {
        if (status === 1) return 'warning'
        if (status === 0) return 'danger'
        if (status === 2 && type === 1 ) return 'info'
        if (status === 2 && type === 2 ) return 'white'
        if (status === 3) return 'success'
        return 
      },
  
      customTab(array) {
        const tab = [];
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          if (
            element.status === 0 
          ) {
            element.setStatus = "En attente";
            tab.push(element);
            continue;
          }
  
          if (element.status === 1 ) {
            element.setStatus = "Offre proposée";
            tab.push(element);
            continue;
          }
  
          if (
            element.status === 2 &&
            element.type_of_payment == 1
          ) {
            element.setStatus = "Avance payée";
            tab.push(element);
            continue;
          }
  
          if (
            element.status === 2 &&
            element.type_of_payment == 2
          ) {
            element.setStatus = "Payée";
            tab.push(element);
            continue;
          }
          if (
            element.status === 3 
          ) {
            element.setStatus = "Terminée";
            tab.push(element);
            continue;
          }
         
        }
        return tab;
      },
  
     
      openWhatsapp(data) {
        window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutations! 
  Suite à votre commande de *${data.service.name}*, nous avons besoin de plus de précision sur votre besoin afin de vous faire des offres compétitives.`)
      },
    },
  }
  </script>
  
  <style>
  i.icon-code {
    display: none !important;
  }
  
  .vgt-select {
    background: transparent !important;
    border-color: #404656 !important;
  }
  
  .itemActions :hover {
    background: transparent !important;
  }
  </style>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";
  </style>
  